<template>
  <div class="main-zone">
    <div class="zone-header">
      <h1 style="font-size: 1.4rem;font-weight: 600;">{{ $t("map.title") }}</h1>
    </div>
    <div class="zone-content">
      <div class="zone-left">
        <div class="zone-card" @click="clockZone(item)" v-for="(item,idx) in zone" :key="idx">
          <div v-if="item.name == 'GPS'" style="background: none">
            <h2><span class="mr-2"><i class="fas fa-map-pin"></i></span> {{ item.name }}</h2>
            <h5>{{ $t("zone.view") }}</h5>
          </div>

          <!--          <div v-if="item.name == 'Wi-Fi'" style="background: none">-->
          <!--            <h2><span class="mr-2"><i class="fas fa-wifi"></i></span> {{ item.name }}</h2>-->
          <!--            <h5>View all Wi-Fi Zone in your Company</h5>-->
          <!--          </div>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zone: [
        {
          id: 1,
          name: "GPS",
          type: "Gps"
        }
      ]
    }
  },
  methods: {
    clockZone(item) {
      if (item.type == 'Gps') {
        this.$router.push({
          name: "Gps.index",
          params: {
            zone_type: item.type
          }
        }).catch(() => {
        })
      }

      if (item.type == 'Wifi') {
        alert('ລໍຖ້າພັດທະນາ')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main-zone {
  // background-color: rgb(255, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  position: relative;

  .zone-header {
  // border: 2px solid blue;
    width: 1100px;
    height: 80px;
    // background-color: yellow;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-left: 40px;

    h1 {
      font-family: $font-family;
      letter-spacing: 1px;
    }
  }

  .zone-content {
    width: 1200px;
    height: 400px;
    // background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 80px;


    .zone-left {
      flex: 1;
      padding: 5px;
      background-color: rgba(223, 228, 234, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      .zone-card {
        width: 500px;
        height: 120px;
        margin: 10px 0;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
        transition: ease-in 0.2s;
        font-family: $font-family;
        text-transform: uppercase;
        background-color: #FFFFFF;

        h2 {
          font-weight: bold;
          text-transform: uppercase;
          font-size: 1.4rem;
        }

        h5 {
          text-transform: capitalize;
          font-weight: normal;
        }
      }

    }

    .zone-left :hover {
      background-color: rgba(112, 161, 255, 1);
      cursor: pointer;

      h2, span, h5 {
        background: none;
        color: white;
      }

      h2 {
        color: white;
      }

      h5 {
        color: #FFFFFF;
        font-weight: normal;
      }

      span {
        color: white;
      }
    }


    .zone-right {
      flex: 1;
      padding: 5px;
      // background-color: #6CB669;
      height: 600px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .card--insert {
        width: 400px;
        height: 500px;
        background-color: rgba(223, 228, 234, 0.6);
        display: flex;
        flex-direction: column;
        border-radius: 8px;

        .card--insert--header {
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;

          h1 {
            font-family: $font-family;
            font-size: 18px;
            text-transform: capitalize;
            letter-spacing: 1.5px;
            color: #4A4A4A;
          }
        }

        .card--form {
          width: 100%;
          height: 100%;
          background-color: red;

          .card-row {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

</style>
